import useTranslation from 'next-translate/useTranslation';
import PropertySection from '@routes/index/components/PropertySection';
import Section from '@routes/index/components/Section';

const PropertySectionContainer = () => {
  const { t } = useTranslation('top');

  return (
    <Section
      hasPaddingHorizontal={false}
      subTitle={t('propertySection.subTitle')}
      title={t('propertySection.title')}
    >
      <PropertySection />
    </Section>
  );
};

export default PropertySectionContainer;
