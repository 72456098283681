import KeyVisualSection from '@routes/index/components/KeyVisualSection';
import TopicSection from '@routes/index/components/TopicSection';
import BenefitSectionContainer from '@routes/index/container/BenefitSectionContainer';
import FaqSectionContainer from '@routes/index/container/FaqSectionContainer';
import FlightSectionContainer from '@routes/index/container/FlightSectionContainer';
import FloatCtaButtonContainer from '@routes/index/container/FloatCtaButtonContainer';
import IntroductionSectionContainer from '@routes/index/container/IntroductionSectionContainer';
import PlanSectionContainer from '@routes/index/container/PlanSectionContainer';
import PropertySectionContainer from '@routes/index/container/PropertySectionContainer';
import TaiwanFeaturedNeighborsSectionContainer from '@routes/index/container/TaiwanFeaturedNeighborsSectionContainer';
import TravelStorySectionContainer from '@routes/index/container/TravelStorySectionContainer';
import styles from './css';

const IndexView = () => {
  return (
    <div className="index-view">
      <KeyVisualSection />
      <IntroductionSectionContainer />
      <PlanSectionContainer />
      <BenefitSectionContainer />
      <PropertySectionContainer />
      <TravelStorySectionContainer />
      <FlightSectionContainer />
      <TaiwanFeaturedNeighborsSectionContainer />
      <FaqSectionContainer />
      <TopicSection />
      <FloatCtaButtonContainer />
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default IndexView;
