import useTranslation from 'next-translate/useTranslation';
import FaqSection from '@routes/index/components/FaqSection';
import Section from '@routes/index/components/Section';

const FaqSectionContainer = () => {
  const { t } = useTranslation('top');

  return (
    <Section title={t('faqSection.title')}>
      <FaqSection />
    </Section>
  );
};

export default FaqSectionContainer;
