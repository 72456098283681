import { FC } from 'react';
import Trans from 'next-translate/Trans';
import PlanCardLabel from '@features/plan-cards/components/PlanCardLabel';
import type { NeighborPlan } from '@services/hafh/types/generated';
import { VIP_DISCOUNT_RATE } from '@utils/constants';
import { useIsJapanUser, useIsKoreaUser, useIsTaiwanUser } from '@utils/hooks';
import { formatDiscountRate } from '@utils/utils';

type Props = {
  plan: NeighborPlan;
  isOnlyPlan: boolean;
  cardType: 'campaign' | 'recommend' | 'basic';
};

const PlanCardLabelContainer: FC<Props> = ({ plan, cardType }) => {
  const isJapanUser = useIsJapanUser();
  const isKoreaUser = useIsKoreaUser();
  const isTaiwanUser = useIsTaiwanUser();

  const getThemeColor = () => {
    if (cardType === 'campaign') {
      return 'pink';
    }
    if (cardType === 'recommend') {
      return 'purple';
    }
    return 'gray';
  };

  const getLabelText = () => {
    if (cardType === 'campaign') {
      return (
        <Trans
          i18nKey="neighbor-plan:campaignLabel"
          values={{
            rate: formatDiscountRate(plan.discount?.rate),
          }}
        />
      );
    }

    if (isTaiwanUser) {
      return (
        <Trans
          i18nKey="neighbor-plan:glbBasicLabel"
          values={{
            rate: VIP_DISCOUNT_RATE.platinum,
          }}
        />
      );
    }

    if (cardType === 'recommend') {
      return <Trans i18nKey="neighbor-plan:recommendedLabel" />;
    }

    if (isJapanUser || isKoreaUser) {
      return <Trans i18nKey="neighbor-plan:basicLabel" />;
    }

    // For Global
    return (
      <Trans
        i18nKey="neighbor-plan:glbBasicLabel"
        values={{
          rate: VIP_DISCOUNT_RATE.platinum,
        }}
      />
    );
  };

  return <PlanCardLabel label={getLabelText()} theme={getThemeColor()} />;
};

export default PlanCardLabelContainer;
