import useTranslation from 'next-translate/useTranslation';
import IntroductionSection from '@routes/index/components/IntroductionSection';
import Section from '@routes/index/components/Section';

const IntroductionSectionContainer = () => {
  const { t } = useTranslation('top');

  return (
    <Section title={t('introductionSection.title')}>
      <IntroductionSection />
    </Section>
  );
};

export default IntroductionSectionContainer;
