import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppSelector } from '@hooks/useAppSelector';
import * as travelStoriesListsActions from '@redux/modules/travelStoriesLists';
import { useActions } from '@utils/hooks';
import type { LANG_LOCALE, TravelStoriesTabsType } from '@utils/types';

type Props = {
  type: TravelStoriesTabsType;
  page?: number;
};

export const useGetTravelStories = ({ type, page = 1 }: Props) => {
  const { locale } = useRouter() as { locale: LANG_LOCALE };
  const { authUser, loadingAuthUser } = useAppSelector((state) => ({
    authUser: state.authUser,
    loadingAuthUser: state.loadingAuthUser,
  }));
  const { getTravelStoriesList } = useActions({
    ...travelStoriesListsActions,
  });

  useEffect(() => {
    if (loadingAuthUser) {
      return;
    }

    getTravelStoriesList(type, page, locale, authUser);
  }, [type, page, loadingAuthUser, locale]);
};
