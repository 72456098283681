import TaiwanFeaturedNeighborCard from '@routes/index/components/TaiwanFeaturedNeighborsSection/TaiwanFeaturedNeighborCard';
import { TAIWAN_FEATURED_NEIGHBORS } from '@routes/index/components/TaiwanFeaturedNeighborsSection/constants';
import styles from './css';

const TaiwanFeaturedNeighborsSection = () => {
  return (
    <div className="taiwan-featured-neighbors-section">
      <div className="cards-cont">
        {TAIWAN_FEATURED_NEIGHBORS.map((taiwanFeaturedNeighbor) => (
          <TaiwanFeaturedNeighborCard
            featuredNeighbor={taiwanFeaturedNeighbor}
            key={taiwanFeaturedNeighbor.id}
          />
        ))}
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default TaiwanFeaturedNeighborsSection;
