import Image from 'next/image';
import { useRouter } from 'next/router';
import { TypoS, TypoXSBold, TypoXXS } from '@atoms/Typos';
import Link from '@components/Link';
import { TaiwanFeaturedNeighbor } from '@routes/index/components/TaiwanFeaturedNeighborsSection/constants';
import routes, { getUrlWithLocale, route } from '@utils/routes';
import styles from './css';

const TaiwanFeaturedNeighborCard = ({
  featuredNeighbor,
}: {
  featuredNeighbor: TaiwanFeaturedNeighbor;
}) => {
  const { desc, id, img, publication_date, title } = featuredNeighbor;
  const router = useRouter();

  return (
    <div className="featured-neighbor-card">
      <Link
        href={getUrlWithLocale(
          router,
          router.locale,
          route(routes.topic, { id })
        )}
      >
        <div className="featured-neighbor-card-cont">
          <Image
            alt={title}
            height={166}
            src={img}
            style={{ height: 'auto', width: '100%' }}
            width={400}
          />
          <div className="content-cont">
            <TypoXXS text={publication_date} />
            <h3 className="title">
              <TypoXSBold text={title} />
            </h3>
            <span className="desc">
              <TypoS color="black700" text={desc} />
            </span>
          </div>
        </div>
      </Link>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default TaiwanFeaturedNeighborCard;
