import css from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .taiwan-featured-neighbors-section {
    .cards-cont {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        flex-direction: row;
        align-items: initial;
      }
    }
  }
`;

export default styles;
