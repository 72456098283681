import PropertyHistoryContainer from '@features/property-history/containers/PropertyHistoryContainer';
import PopularPropertiesSection from '@routes/index/components/PopularPropertiesSection';
import styles from './css';

const PropertySection = () => {
  return (
    <div className="property-section">
      <PropertyHistoryContainer />
      <PopularPropertiesSection />
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default PropertySection;
