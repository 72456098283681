import Image from 'next/image';
import { SwiperSlide } from 'swiper/react';
import Swiper from '@atoms/Swiper';
import Link from '@components/Link';
import { useNeighbor } from '@hooks/useNeighbor';
import { pushDataLayer } from '@utils/spa-ga4';
import { isEmpty } from '@utils/utils';
import styles from './css';

type Props = {
  banners: {
    image_url: string;
    link: string;
  }[];
  isMobile?: boolean;
  mode?: 'dark';
};

const BannerSlider = ({ banners, isMobile, mode }: Props) => {
  const { neighbor } = useNeighbor();

  if (isEmpty(banners)) {
    return null;
  }

  let displayBanners = banners;
  const shouldLoop = isMobile
    ? displayBanners.length > 1
    : displayBanners.length > 3;

  if (shouldLoop && displayBanners.length < 5) {
    // banners are not duplicated correctly when looping with swiper ver.11
    displayBanners = [...displayBanners, ...displayBanners];
  }

  const options = {
    centerInsufficientSlides: true,
    centeredSlides: shouldLoop,
    loop: shouldLoop,
    pagination: isMobile && {
      clickable: true,
    },
    slidesPerView: 'auto',
    spaceBetween: 16,
  } as const;

  const onClickSlide = (link: string) => {
    const topicsId = link.split('?')[0].split('/').slice(-1)[0];
    pushDataLayer({
      campaign_code: '',
      event: 'gtm-click',
      event_name: `browse_carousel_topics_${topicsId}`,
      neighbor_id: neighbor?.id,
    });
  };

  return (
    <div className={`banner-slider ${mode}`}>
      <div className="banner-slider-inner">
        <Swiper hasSwiperButton={shouldLoop} name="banner" options={options}>
          {displayBanners.map((bannerAttribute) => (
            <SwiperSlide
              className="swiper-slide banner-slider-item"
              key={bannerAttribute.image_url}
            >
              <Link
                href={bannerAttribute.link}
                onClick={() => onClickSlide(bannerAttribute.link)}
              >
                <Image
                  alt={bannerAttribute.link}
                  className="banner-slider-image"
                  height={192}
                  src={bannerAttribute.image_url}
                  width={343}
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default BannerSlider;
