import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { TypoLBold, TypoXLBold } from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import type {
  IntroductionSectionKeyOfKebab,
  IntroductionSectionKeyOfLowerCamel,
} from '@routes/index/constants';
import IntroductionDetail from '../IntroductionDetail';
import styles from './css';

type Props = {
  sectionKeyNameOfLowerCamel: IntroductionSectionKeyOfLowerCamel;
  sectionKeyNameOfKebab: IntroductionSectionKeyOfKebab;
};

const IntroductionItem: FC<Props> = ({
  sectionKeyNameOfKebab,
  sectionKeyNameOfLowerCamel,
}) => {
  const { t } = useTranslation('top');

  return (
    <div className="introduction-item">
      <div className="introduction-item-head">
        <Mobile>
          <TypoLBold>
            {t(`introductionSection.${sectionKeyNameOfLowerCamel}.title`)}
          </TypoLBold>
        </Mobile>
        <Desktop>
          <TypoXLBold>
            {t(`introductionSection.${sectionKeyNameOfLowerCamel}.title`)}
          </TypoXLBold>
        </Desktop>
      </div>
      <div className="introduction-item-body">
        {Array.from({ length: 2 }).map((_, index) => (
          <IntroductionDetail
            detailIndex={index + 1}
            key={sectionKeyNameOfKebab + index}
            sectionKeyNameOfKebab={sectionKeyNameOfKebab}
            sectionKeyNameOfLowerCamel={sectionKeyNameOfLowerCamel}
          />
        ))}
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default IntroductionItem;
