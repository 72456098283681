import BenefitSection from '@routes/index/components/BenefitSection';
import { useIsJapanUser } from '@utils/hooks';

const BenefitSectionContainer = () => {
  const isJapanUser = useIsJapanUser();

  if (!isJapanUser) {
    return null;
  }

  return <BenefitSection />;
};

export default BenefitSectionContainer;
