import useTranslation from 'next-translate/useTranslation';
import PropertySlider from '@components/molecules/PropertySlider';
import { useGetPropertyHistory } from '@features/property-history/hooks/useGetPropertyHistory';
import { useAppSelector } from '@hooks/useAppSelector';

const PropertyHistoryContainer = () => {
  const { t } = useTranslation('browse');
  const { propertyHistory } = useAppSelector((state) => ({
    propertyHistory: state.propertyHistory,
  }));

  useGetPropertyHistory();

  if (!propertyHistory.properties.length) {
    return null;
  }

  return (
    <PropertySlider
      name="property-history"
      properties={propertyHistory.properties}
      title={t('propertyHistory')}
    />
  );
};

export default PropertyHistoryContainer;
