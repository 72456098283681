import useTranslation from 'next-translate/useTranslation';
import Section from '@routes/index/components/Section';
import TravelStorySection from '@routes/index/components/TravelStorySection';

const TravelStorySectionContainer = () => {
  const { t } = useTranslation('top');

  return (
    <Section
      hasPaddingHorizontal={false}
      subTitle={t('travelStorySection.subTitle')}
      title={t('travelStorySection.title')}
    >
      <TravelStorySection />
    </Section>
  );
};

export default TravelStorySectionContainer;
