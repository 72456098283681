import { FC } from 'react';
import classNames from 'classnames';
import { TypoLBold, TypoS } from '@atoms/Typos';
import styles from './css';

type Props = {
  children: React.ReactNode;
  title: string;
  subTitle?: string;
  hasBorder?: boolean;
  hasTriangle?: boolean;
  hasPaddingHorizontal?: boolean;
};

const Section: FC<Props> = ({
  children,
  title,
  subTitle,
  hasBorder = true,
  hasTriangle = false,
  hasPaddingHorizontal = true,
}) => {
  return (
    <section
      className={classNames(
        'section',
        hasBorder && 'has-border',
        hasPaddingHorizontal && 'has-padding-horizontal'
      )}
    >
      <div className="section-head">
        <TypoLBold>{title}</TypoLBold>
        {subTitle && (
          <>
            <div className="mt-12" />
            <TypoS>{subTitle}</TypoS>
          </>
        )}
        <div className="mt-32" />
        <span className="section-head-border" />
      </div>
      {children}
      {hasTriangle && <div className="has-triangle" />}
      <style jsx={true}>{styles}</style>
    </section>
  );
};

export default Section;
