import useTranslation from 'next-translate/useTranslation';
import { useAppSelector } from '@hooks/useAppSelector';
import PropertySlider from '@molecules/PropertySlider';
import * as usedCoinsRankingActions from '@redux/modules/usedCoinsRanking';
import { useActions } from '@utils/hooks';

const PopularPropertiesSection = () => {
  const { t } = useTranslation('browse');

  const { usedCoinsRanking } = useAppSelector((state) => ({
    usedCoinsRanking: state.usedCoinsRanking,
  }));
  const { getUsedCoinsRanking } = useActions({
    ...usedCoinsRankingActions,
  });

  return (
    <div>
      {!!usedCoinsRanking?.length && (
        <PropertySlider
          getPropertiesByArea={getUsedCoinsRanking}
          isAreaTabs={true}
          name="used-coin-ranking"
          properties={usedCoinsRanking}
          sliderType="ranking"
          title={t('reservationCount')}
        />
      )}
    </div>
  );
};

export default PopularPropertiesSection;
