import classNames from 'classnames';
import { TypoXXSBold } from '@atoms/Typos';
import styles from './css';

type Props = {
  theme: 'pink' | 'purple' | 'gray';
  label: string | JSX.Element;
};

const PlanCardLabel = ({ theme, label }: Props) => (
  <div className={classNames('plan-card-label', `theme-${theme}`)}>
    <TypoXXSBold>{label}</TypoXXSBold>
    <style jsx={true}>{styles}</style>
  </div>
);

export default PlanCardLabel;
