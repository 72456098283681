import PlanCards from '@features/plan-cards/components/PlanCards';
import { useAppSelector } from '@hooks/useAppSelector';
import useLocation from '@hooks/useLocation';
import useRegistrationCountry from '@hooks/useRegistrationCountry';
import { JAL_PLAN_CODES } from '@utils/constants';
import { isEmpty } from '@utils/utils';
import { isNewNeighborPlanCodeForJapan } from '@utils/validation';

const PlanCardsContainer = () => {
  const { isJapan } = useLocation();
  const { isRegistrationCountryJapan } = useRegistrationCountry();
  const { neighborPlans, neighbor } = useAppSelector((state) => ({
    neighbor: state.neighbor,
    neighborPlans: state.neighborPlans,
  }));

  const neighborPlansToShowGeneral = neighborPlans
    .filter((plan) => plan !== undefined)
    .filter((plan) => plan.kind !== 'snooze')
    .filter((plan) => plan.monthly_price >= 0);

  const neighborPlansToShowJapan = neighborPlansToShowGeneral
    .filter((plan) => plan.campaign || !JAL_PLAN_CODES.includes(plan.code))
    .filter((plan) => isNewNeighborPlanCodeForJapan(plan.code));

  const neighborPlansToShow = (() => {
    if (!isEmpty(neighbor)) {
      return isRegistrationCountryJapan
        ? neighborPlansToShowJapan
        : neighborPlansToShowGeneral;
    }
    return isJapan ? neighborPlansToShowJapan : neighborPlansToShowGeneral;
  })();

  const orderedNeighborPlansToShow = neighborPlansToShow.sort((a, b) => {
    return Number(b.recommend) - Number(a.recommend);
  });

  const isOnlyPlan = orderedNeighborPlansToShow.length === 1;

  return (
    <PlanCards isOnlyPlan={isOnlyPlan} plans={orderedNeighborPlansToShow} />
  );
};

export default PlanCardsContainer;
