import useTranslation from 'next-translate/useTranslation';
import { useAppSelector } from '@hooks/useAppSelector';
import FlightSection from '@routes/index/components/FlightSection';
import Section from '@routes/index/components/Section';
import { useIsJapanUser } from '@utils/hooks';
import { isEmpty } from '@utils/utils';

const FlightSectionContainer = () => {
  const { t } = useTranslation('top');
  const isJapanUser = useIsJapanUser();

  const { neighbor } = useAppSelector((state) => ({
    neighbor: state.neighbor,
  }));

  if (isEmpty(neighbor) || !isJapanUser) {
    return null;
  }

  return (
    <Section
      hasPaddingHorizontal={false}
      subTitle={t('flightSection.subTitle')}
      title={t('flightSection.title')}
    >
      <FlightSection />
    </Section>
  );
};

export default FlightSectionContainer;
