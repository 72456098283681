/* eslint-disable */
import { DEFAULT_IMAGE } from '@utils/constants';

const hasNoImage = (topic: any) =>
  !topic._embedded['wp:featuredmedia'] ||
  !topic._embedded['wp:featuredmedia'][0] ||
  !topic._embedded['wp:featuredmedia'][0].media_details ||
  !topic._embedded['wp:featuredmedia'][0].media_details.sizes;

const getOgImage = (topic: any) => {
  if (hasNoImage(topic)) return DEFAULT_IMAGE;
  const sizes = topic._embedded['wp:featuredmedia'][0].media_details.sizes;
  if (sizes.full) return sizes.full.source_url;
  if (sizes.large) return sizes.large.source_url;
  if (sizes.medium_large) return sizes.medium_large.source_url;
  if (sizes.medium) return sizes.medium.source_url;
  return DEFAULT_IMAGE;
};

const getThumbnail = (topic: any) => {
  if (hasNoImage(topic)) return DEFAULT_IMAGE;
  const sizes = topic._embedded['wp:featuredmedia'][0].media_details.sizes;
  if (sizes.medium_large) return sizes.medium_large.source_url;
  if (sizes.medium) return sizes.medium.source_url;
  if (sizes.large) return sizes.large.source_url;
  if (sizes.full) return sizes.full.source_url;
  return DEFAULT_IMAGE;
};

export const getTopicOgImage = (topic: any) => {
  const ogImageUrl = getOgImage(topic);
  return encodeURI(ogImageUrl);
};

export const getTopicThumbnail = (topic: any) => {
  const thumbnailUrl = getThumbnail(topic);
  return encodeURI(thumbnailUrl);
};

export default getTopicOgImage;
