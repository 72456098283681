import useTranslation from 'next-translate/useTranslation';
import Section from '@routes/index/components/Section';
import TaiwanFeaturedNeighborsSection from '@routes/index/components/TaiwanFeaturedNeighborsSection';
import { useIsTaiwanUser } from '@utils/hooks';

const TaiwanFeaturedNeighborsSectionContainer = () => {
  const { t } = useTranslation('top');
  const isTaiwanUser = useIsTaiwanUser();

  if (!isTaiwanUser) {
    return null;
  }

  return (
    <Section title={t('taiwanFeaturedNeighborsSection.title')}>
      <TaiwanFeaturedNeighborsSection />
    </Section>
  );
};

export default TaiwanFeaturedNeighborsSectionContainer;
