import useTranslation from 'next-translate/useTranslation';
import { TypoXLBold, TypoXXXXLBold } from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import HafhGuide from '@components/molecules/HafhGuide';
import { INTRODUCTION_SECTION_KEYS } from '@routes/index/constants';
import { useIsJapanUser } from '@utils/hooks';
import IntroductionItem from '../IntroductionItem';
import styles from './css';

const IntroductionSection = () => {
  const { t } = useTranslation('top');
  const isJapanUser = useIsJapanUser();

  const getJapanRegionCatchphrase = () => {
    if (isJapanUser) {
      return `introductionSection.catchphrase-ja`;
    }

    return `introductionSection.catchphrase`;
  };

  return (
    <div className="introduction-section">
      <div className="introduction-section-catch">
        <Mobile>
          <TypoXLBold>{t(getJapanRegionCatchphrase())}</TypoXLBold>
        </Mobile>
        <Desktop>
          <TypoXXXXLBold>{t(getJapanRegionCatchphrase())}</TypoXXXXLBold>
        </Desktop>
      </div>
      <div className="mt-40" />
      <div className="introduction-section-list">
        {INTRODUCTION_SECTION_KEYS.map((item) => (
          <IntroductionItem
            key={item.kebabCase}
            sectionKeyNameOfKebab={item.kebabCase}
            sectionKeyNameOfLowerCamel={item.lowerCamelCase}
          />
        ))}
      </div>
      <HafhGuide />
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default IntroductionSection;
