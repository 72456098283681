import useTranslation from 'next-translate/useTranslation';
import PlanSection from '@routes/index/components/PlanSection';
import Section from '@routes/index/components/Section';
import { useIsJapanUser } from '@utils/hooks';

const PlanSectionContainer = () => {
  const { t } = useTranslation('top');
  const isJapanUser = useIsJapanUser();

  // Japan region displays the benefit section, and if there is a benefit section, displays a triangle.
  return (
    <Section
      hasBorder={!isJapanUser}
      hasTriangle={isJapanUser}
      title={t('planSection.title')}
    >
      <PlanSection />
    </Section>
  );
};

export default PlanSectionContainer;
