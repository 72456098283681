import { useGetTopicCategories } from '@features/topics/hooks/useGetTopicCategories';
import { useGetTravelStories } from '@features/travel-stories/hooks/useGetTravelStories';
import IndexView from '@routes/index/view/IndexView';

const IndexContainer = () => {
  useGetTopicCategories({
    slugs: ['property-release', 'experience'],
  });
  useGetTravelStories({
    type: 'latest',
  });
  useGetTravelStories({
    type: 'popular',
  });

  return <IndexView />;
};

export default IndexContainer;
